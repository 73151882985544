import React, { Fragment } from 'react'
import Props from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import smoothscroll from 'smoothscroll-polyfill'

import config from 'config'
import { theme } from 'common/styles'
import GlobalStyles from 'components/Styled/global-styles'
import Footer from 'components/Footer'
import { isFooter } from '../../common/helpers'

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
}

const Page = ({ children, title, translates }) => {
  const isWebQuery =
    typeof location !== 'undefined' ? isFooter(location.search) : ''

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet>
          {/* Cookie yes */}
          {config.env === 'production' && (
            <script
              id="cookieyes"
              type="text/javascript"
              src="https://cdn-cookieyes.com/client_data/b821c8fa49d2e6517de68153/script.js"
            ></script>
          )}

          <title>{title ? `${title} | Zoe` : 'Zoe'}</title>
          {/* Schema.org for Google */}
          <meta itemProp="name" content="Zoe" />
          <meta
            itemProp="description"
            content="The World’s Best Ranked Dating App for Queer Women"
          />
          <meta itemProp="image" content={`${config.baseUrl}/og-image.png`} />
          {/* Open Graph general (Facebook, Pinterest & Google+) */}
          <meta name="og:title" content="Zoe" />
          <meta
            name="og:description"
            content="The World’s Best Ranked Dating App for Queer Women"
          />
          <meta name="og:image" content={`${config.baseUrl}/og-image.png`} />
          <meta name="og:url" content={config.baseUrl} />
          <meta name="og:type" content="website" />
          {/* Schema.org for Google */}
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/icons/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/icons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/icons/favicon-16x16.png"
          />
          <link rel="manifest" href="/icons/site.webmanifest" />
          <link
            rel="mask-icon"
            href="/icons/safari-pinned-tab.svg"
            color="#9f2ffe"
          />
          <link rel="shortcut icon" href="/icons/favicon.ico" />
          <meta name="apple-mobile-web-app-title" content="Zoe" />
          <meta name="application-name" content="Zoe" />
          <meta name="msapplication-TileColor" content="#9f2ffe" />
          <meta
            name="msapplication-config"
            content="/icons/browserconfig.xml"
          />
          <meta name="theme-color" content="#ffffff" />
          {/* App Store app ID */}
          <meta
            name="apple-itunes-app"
            content={`app-id=${config.appStoreAppID}`}
          />
          {/* Stylesheets */}
          <link rel="stylesheet" href="https://use.typekit.net/zoq2onl.css" />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-9DER49R1QG"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-9DER49R1QG');
          `}
          </script>
        </Helmet>
        {children}
        {!isWebQuery && <Footer translates={translates} />}
        <GlobalStyles />
      </Fragment>
    </ThemeProvider>
  )
}

Page.propTypes = {
  children: Props.any,
  title: Props.string,
  translates: Props.any.isRequired,
}

Page.defaultProps = {
  children: null,
  title: '',
}

export default Page
