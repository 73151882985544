export default {
  appStoreLink:
    'https://itunes.apple.com/us/app/zoe-lesbian-dating-chat-app/id1269081011?mt=8',
  appStoreAppID: 1269081011,
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=com.surgeapp.zoe&referrer=utm_source%3DWEBCLICKANDROID%26utm_medium%3DWEB%26utm_campaign%3DANDROIDCLICK',
  facebook: 'https://www.facebook.com/Zoegirlapp/',
  instagram: 'https://www.instagram.com/zoegirlsapp/',
  twitter: 'https://twitter.com/Zoegirlsapp',
  baseUrl: 'https://zoeapp-d3216.firebaseapp.com',
  googleAnalyticsUA: 'UA-154740103-1',
  // eslint-disable-next-line no-undef
  apiURL: process.env.GATSBY_API_URL,
  // eslint-disable-next-line no-undef
  env: process.env.CONTEXT,
}
